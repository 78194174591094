<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Template Event</h5>
                <div>
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                </div>
            </div>
            <div class="card">
                <TabView>
                    <TabPanel header="Add Event">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="eventNameId">
                                    Event name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="eventNameId" class="p-text-capitalize" v-model.trim="evt.eventName"
                                    required="true" maxlength="50" autofocus
                                    :class="{ 'p-invalid': submitted && !evt.eventName }" />
                                <small class="p-invalid p-error" v-if="v$.evt.eventName.$error">{{
                        v$.evt.eventName.$errors[0].$message
                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="ChannelId">
                                    Channel Name
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <MultiSelect id="ChannelId" v-model="evt.ChannelName" :options="ChannelIdList"
                                    optionValue="value" optionLabel="label" placeholder="Select" :filter="true"
                                    class="multiselect-custom" appendTo="body"></MultiSelect>
                                <small class="p-invalid p-error" v-if="v$.evt.ChannelName.$error">{{
                        v$.evt.ChannelName.$errors[0].$message
                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-2 p-d-flex p-pt-2">
                                <Button v-if="!showLoader" label="Submit" icon="pi pi-check"
                                    class="p-button-text p-ml-auto" @click="addUpdateTemplateEvents"
                                    style="width: 90px"></Button>
                                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color"
                                    class="p-button-text p-ml-auto" style="width: 90px"></Button>

                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
import { required, helpers } from '@vuelidate/validators';

export default {
    data() {
        return {
            v$: useValidate(),
            templateEventList: [],
            rcsTemplateEventList: [],
            setting_id: 0,
            tabOpenStatus: [],
            evt: {
                ChannelName: '',
                eventName: '',
                evtStatusType: 1,
            },
            ChannelIdList: [
                { label: 'WhatsApp', value: 1 },
                { label: 'RCS', value: 2 },
                { label: 'Sms', value: 3 },
                { label: 'Email', value: 4 },
            ],
            showloaderpage: false,
            showLoader: false,
            submitted: false,
            errormsg: '',
            systemUrlErr: '',
            localActionId: '',
            routeParam: '',  
        };
    },

    validations() {
        return {
            evt: {
                ChannelName: { required: helpers.withMessage('Please select channel name', required) },
                eventName: { required: helpers.withMessage('Please enter event name', required) },
            },
        };
    },

    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.eventId;
        this.localActionId = localStorage.localActionId;
        if(this.routeParam != 0){
        this.getCurrentAdminTemplateEvents({ actionId: this.localActionId, eventId: this.routeParam });
        }
    },
    methods: {
        getCurrentAdminTemplateEvents() {
            this.loading = true;
            this.ApiService.getCurrentAdminTemplateEvents({ actionId: this.localActionId, eventId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.evt.eventName = data.result.te3;
                    if (data.result.te4) {
                        this.tabOpenStatus = data.result.te4;
                        let te4Array = data.result.te4.split(',').map(Number);
                        this.evt.ChannelName = te4Array;
                    }
                    this.evt.evtStatusType = data.result.te5;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        addUpdateTemplateEvents() {
            this.submitted = true;
            this.errormsg = '';
            this.v$.evt.$validate();
            if (!this.v$.evt.$error) {
                let fields = {};
                fields['te1'] = this.routeParam;
                fields['te2'] = this.localActionId;
                fields['te3'] = this.evt.eventName;
                fields['te4'] = this.evt.ChannelName;
                fields['te5'] = this.evt.evtStatusType;
                this.showLoader = true;
                this.ApiService.addUpdateTemplateEvents(fields).then((items) => {
                    if (items.status == 200) {
                        this.tabOpenStatus = this.evt.ChannelName;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.routeParam = items.te_id;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        goToPrevious() {
            router.push({
                name: "listAdminTemplateEvent",
            });
        },
    },
};
</script>

<style scoped></style>
